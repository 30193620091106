import { DateTimeValue, timeRangeToDisplayString } from 'lib/time'
import classNames from 'lib/classnames'

interface Props {
  startTime: DateTimeValue
  endTime: DateTimeValue
  className?: string
}

export function TimeRangeLabel({ startTime, endTime, className }: Props) {
  const timeRange = timeRangeToDisplayString(startTime, endTime)
  return (
    <div
      className={classNames(
        className,
        'rounded bg-gray-100 px-2 py-0.5 text-xs font-medium text-gray-800 whitespace-nowrap truncate'
      )}
      title={timeRange}
    >
      {timeRange}
    </div>
  )
}
