// import Table from '../common/table/Table'
import { useCallback, useMemo, useRef, useEffect } from 'react'
import { ColumnDef, SortingState } from '@tanstack/react-table'
import { LogQueryResponse } from 'gen/service/analytic'
import { EventLogEntry } from 'gen/service/common'
import Image from 'next/image'
import { logColumnToTableColumn, logsColumns as LogsColumns, logsColumnStates } from 'components/logs/atoms'
import { useAtom } from 'jotai'
import ResizeTable from 'components/common/table/ResizeTable'
import { useResizeDetector } from 'react-resize-detector'
import { isEqual, isString } from 'lodash'
import { defaultColumns } from 'components/logs/LogConstants'

export function LogTableEmpty({ isUsingProjectView }: { isUsingProjectView?: boolean }) {
  return (
    <div className="flex h-full items-center justify-center">
      <div>
        <h1 className="text-3xl font-medium">No matching results found</h1>
        <div className="flex space-x-4 py-4">
          <ul className="">
            <li className="py-2">
              <h3 className="text-sm font-medium">Expand your time range</h3>
              <span className="text-sm text-gray-700">Try expanding your time range to see more results.</span>
            </li>
            <li className="py-2">
              <h3 className="text-sm font-medium">Adjust your query</h3>
              <span className="text-sm text-gray-700">Try searching for a different criteria.</span>
            </li>
            {isUsingProjectView ? (
              <li className="py-2">
                <h3 className="text-sm font-medium">Reset your project view</h3>
                <span className="text-sm text-gray-700">Try reset your project view.</span>
              </li>
            ) : null}
          </ul>
          <Image src={'/empty.svg'} width={88} height={88} alt="empty icon" className="mx-auto" />
        </div>
      </div>
    </div>
  )
}

interface Props {
  sortings?: SortingState
  data?: LogQueryResponse[]
  onSortChange?: (sortBy: SortingState) => void
  isLoadingMore?: boolean
  onFetchMore?: () => void
  isReachingEnd?: boolean
  className?: string
  onClick?: (log: EventLogEntry) => void
  isUsingProjectView?: boolean
}

export function LogTable({
  data,
  isLoadingMore,
  onFetchMore,
  className,
  onClick,
  isReachingEnd,
  isUsingProjectView
}: Props) {
  const [logsColumns, dispatch] = useAtom(LogsColumns)
  const [colState, dispatchStates] = useAtom(logsColumnStates)
  const tableContainerRef = useRef<any>(null)

  const resetState = useCallback(
    (payload: any) => {
      dispatchStates({
        type: 'reset',
        payload
      })
    },
    [dispatchStates]
  )

  const { columns, rows, total } = useMemo(() => {
    const columns: any[] = []
    logsColumns.forEach((col) => {
      const targetCol = defaultColumns.find((c) => c.id === col.id)
      if (targetCol) {
        columns.push({
          ...logColumnToTableColumn(col),
          ...targetCol
        })
      } else {
        columns.push(logColumnToTableColumn(col))
      }
    })
    const rows = (data || []).reduce((acc, response) => {
      return acc.concat(response?.entries || [])
    }, [] as EventLogEntry[])
    let total = data ? data[0]?.total || 0 : 0
    total = isString(total) ? parseInt(total) : total
    return { columns, rows, total }
  }, [data, logsColumns])

  const onClickRow = useCallback(
    (row) => {
      onClick && onClick(row.original as EventLogEntry)
    },
    [onClick]
  )

  const hasData = total > 0 || isLoadingMore

  const { ref, height, width } = useResizeDetector()
  const onColumnRemove = useCallback(
    (column: ColumnDef<any>) => {
      dispatch({
        type: 'remove',
        payload: {
          id: column.id
        }
      })
    },
    [dispatch]
  )

  const columnsRef = useRef<any>(null)
  useEffect(() => {
    // detect new column is added
    if (columnsRef.current && tableContainerRef && tableContainerRef.current) {
      const preColumns = columnsRef.current
      try {
        if (
          preColumns.length + 1 === columns.length &&
          isEqual(
            preColumns.map((item) => item.id),
            columns.slice(0, -1).map((item) => item.id)
          )
        ) {
          tableContainerRef.current?.scrollTo({
            left: tableContainerRef.current?.scrollWidth
          })
        }
      } catch {
        //do nothing
      }
    }
    columnsRef.current = columns
  }, [columns])

  return (
    <div className={className} ref={ref}>
      {!hasData && <LogTableEmpty isUsingProjectView={isUsingProjectView} />}
      {hasData && (
        <ResizeTable
          columnResizeMode="onChange"
          onFetchMore={onFetchMore}
          isFetching={isLoadingMore}
          hasMore={!isReachingEnd}
          columns={columns}
          data={rows}
          height={height}
          allowSort
          allowResizeColumn
          onClick={onClickRow}
          state={colState as any}
          onStateChange={resetState}
          onColumnRemove={onColumnRemove}
          minSize={100}
          manualSorting
          ref={tableContainerRef}
          minWidth={width !== undefined && width > 15 ? width - 15 : undefined}
        />
      )}
    </div>
  )
}
