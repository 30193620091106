import React, { useEffect, useRef } from 'react'
import { Markdown } from '../common/markdown/Markdown'
import { Note, NoteFontSize, NoteAlignment, NoteVerticalAlignment } from 'gen/service/web'
import classNames from 'lib/classnames'

interface Props {
  content?: Note['content']
  fontSize?: NoteFontSize
  align?: NoteAlignment
  verticalAlign?: NoteVerticalAlignment
  inPanel?: boolean
  backgroundColor?: string
  textColor?: string
}

const FontSizeMap = {
  [NoteFontSize.SM]: 'text-sm',
  [NoteFontSize.MD]: 'text-base',
  [NoteFontSize.LG]: 'text-lg',
  [NoteFontSize.XL]: 'text-xl',
  [NoteFontSize.XXL]: 'text-2xl'
}

const AlignmentMap = {
  [NoteAlignment.LEFT]: 'text-left',
  [NoteAlignment.CENTER]: 'text-center',
  [NoteAlignment.RIGHT]: 'text-right'
}

const VerticalAlignmentMap = {
  [NoteVerticalAlignment.TOP]: 'self-start',
  [NoteVerticalAlignment.MIDDLE]: 'self-center',
  [NoteVerticalAlignment.BOTTOM]: 'self-end'
}

const overrideClassNames = [
  'prose-headings:!text-inherit',
  'prose-lead:!text-inherit',
  'prose-h1:!text-inherit',
  'prose-h2:!text-inherit',
  'prose-h3:!text-inherit',
  'prose-h4:!text-inherit',
  'prose-p:!text-inherit',
  'prose-a:!text-inherit',
  'prose-blockquote:!text-inherit',
  'prose-pre:!text-inherit',
  'prose-thead:!text-inherit',
  'prose-ol:!text-inherit',
  'prose-ul:!text-inherit',
  'prose-li:!text-inherit',
  'prose-th:!text-inherit',
  'prose-code:!text-inherit',
  'prose-strong:!text-inherit'
]

export const NoteMarkdown = ({
  content,
  fontSize,
  align,
  verticalAlign,
  inPanel,
  backgroundColor,
  textColor
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (ref.current) {
      const markdownContainer = ref.current.querySelector('.prose')
      if (markdownContainer) {
        markdownContainer.setAttribute('style', `color: ${textColor}`)
      }
      ref.current.style.backgroundColor = backgroundColor || ''
    }
  }, [textColor, backgroundColor])
  const extraClassNames = textColor ? overrideClassNames : []
  return (
    <section className="grid" ref={ref}>
      <Markdown
        content={content ?? ''}
        className={classNames(
          'mx-6 max-w-full py-8',
          fontSize ? FontSizeMap[fontSize] : 'text-base',
          align ? AlignmentMap[align] : 'text-left',
          verticalAlign ? VerticalAlignmentMap[verticalAlign] : 'self-start',
          ...extraClassNames
        )}
      />
    </section>
  )
}

export const MemoNoteMarkdown = React.memo(NoteMarkdown)
