import { memo, useMemo, useRef } from 'react'
import {
  ArrowDownTrayIcon,
  ArrowsPointingOutIcon,
  ArrowTopRightOnSquareIcon,
  CameraIcon,
  ClipboardDocumentIcon,
  Cog6ToothIcon,
  CommandLineIcon,
  PencilIcon,
  Square2StackIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import { ExportChartMenu } from '../menu/ExportChartMenu'
import { ExtraSettingMenu } from '../menu/ExtraSettingMenu'
import { PopupMenuButton } from '../menu/PopupMenuButton'
import { isProjectFreeTier } from 'lib/data/atoms'
import { useAtomValue } from 'jotai'
import { ExportCurlLinkHint } from 'components/tier/LimitMessages'
import useUser from 'lib/data/use-user'
import { ArrowPathIcon } from '@heroicons/react/24/solid'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

function humanizeTime(_time?: string) {
  if (!_time) {
    return _time
  }
  const time = dayjs(_time)
  return time.fromNow()
}

interface Props {
  onMenuSelect: (selectKey: string) => void
  allowEdit?: boolean
  allowExport?: boolean
  allowFullScreen?: boolean
}

const Buttons = {
  fullscreen: {
    label: 'Full screen',
    icon: <ArrowsPointingOutIcon className="mr-2 w-4" />,
    key: 'fullscreen'
  },
  export: {
    label: 'Export',
    icon: <ArrowTopRightOnSquareIcon className="mr-2 w-4" />,
    key: 'export',
    items: [
      [
        {
          label: 'Take a snapshot',
          icon: <CameraIcon className="mr-2 w-4" />,
          key: 'snapshot'
        }
      ],
      [
        {
          key: 'png',
          label: 'Export as PNG',
          icon: <ArrowTopRightOnSquareIcon className="mr-2 w-4" />
        },
        {
          key: 'svg',
          label: 'Export as SVG',
          icon: <ArrowDownTrayIcon className="mr-2 w-4" />
        },
        {
          key: 'csv',
          label: 'Export as CSV',
          icon: <ArrowDownTrayIcon className="mr-2 w-4" />
        }
      ],
      [
        {
          label: 'Export as cURL',
          icon: <CommandLineIcon className="mr-2 w-4" />,
          key: 'curl'
        }
      ]
    ]
  },
  exportFreeTier: {
    label: 'Export',
    icon: <ArrowTopRightOnSquareIcon className="mr-2 w-4" />,
    key: 'export',
    items: [
      [
        {
          label: 'Take a snapshot',
          icon: <CameraIcon className="mr-2 w-4" />,
          key: 'snapshot'
        }
      ],
      [
        {
          key: 'png',
          label: 'Export as PNG',
          icon: <ArrowTopRightOnSquareIcon className="mr-2 w-4" />
        },
        {
          key: 'svg',
          label: 'Export as SVG',
          icon: <ArrowDownTrayIcon className="mr-2 w-4" />
        },
        {
          key: 'csv',
          label: 'Export as CSV',
          icon: <ArrowDownTrayIcon className="mr-2 w-4" />
        }
      ],
      [
        {
          label: 'Export as cURL',
          icon: <CommandLineIcon className="mr-2 w-4" />,
          key: 'curl',
          disabled: true,
          disabledHint: <ExportCurlLinkHint />
        }
      ]
    ]
  },
  refresh: {
    label: 'Refresh panel',
    icon: <ArrowPathIcon className="mr-2 w-4" />,
    key: 'refresh'
  },
  edit: {
    label: 'Edit panel',
    icon: <PencilIcon className="mr-2 w-4" />,
    key: 'edit'
  },
  clone: {
    label: 'Clone',
    icon: <Square2StackIcon className="mr-2 w-4" />,
    key: 'clone'
  },
  copy: {
    label: 'Copy configuration',
    icon: <ClipboardDocumentIcon className="mr-2 w-4" />,
    key: 'copy'
  },
  delete: {
    label: 'Delete',
    key: 'delete',
    status: 'danger',
    icon: <TrashIcon className="mr-2 w-4" />
  }
}

const DashboardButtons = ({ allowEdit, onMenuSelect, allowExport = true, allowFullScreen = true }: Props) => {
  const menuRef = useRef<HTMLDivElement>(null)
  const isFreeTier = useAtomValue(isProjectFreeTier)
  const { user } = useUser()
  const exportButton = allowExport ? <ExportChartMenu allowEdit={allowEdit} onSelect={onMenuSelect} /> : null
  const fullScreenButton = allowFullScreen ? (
    <button
      type="button"
      className="text-gray w-6 px-1 hover:text-gray-500"
      aria-label="Full screen"
      onClick={() => {
        onMenuSelect('fullscreen')
      }}
    >
      <ArrowsPointingOutIcon />
    </button>
  ) : null

  const items: IMenuItem[][] = useMemo(() => {
    const ret: any[] = []
    const exportItems = isFreeTier || !user ? [Buttons.exportFreeTier] : [Buttons.export]
    if (allowFullScreen || allowExport) {
      ret.push([...(allowFullScreen ? [Buttons.fullscreen] : []), ...(allowExport ? exportItems : [])])
    }
    const editButtons: any[] = []
    editButtons.push(Buttons.refresh, Buttons.edit)
    ret.push(editButtons)
    if (allowEdit) {
      ret.push([Buttons.clone, Buttons.copy], [Buttons.delete])
    }
    return ret
  }, [allowEdit, allowExport, allowFullScreen, isFreeTier, user])

  return (
    <>
      <div className="hidden group-[.xs]:flex" ref={menuRef}>
        <PopupMenuButton
          onSelect={onMenuSelect}
          items={items}
          ariaLabel="dropdown menu"
          buttonIcon={<Cog6ToothIcon className="w-4" />}
        />
      </div>
      <div className="flex group-[.xs]:hidden">
        {fullScreenButton}
        {exportButton}
        <button
          type="button"
          className="text-gray w-6 px-1 hover:text-gray-500"
          aria-label="Edit panel"
          onClick={() => {
            onMenuSelect('edit')
          }}
        >
          <PencilIcon />
        </button>
        {allowEdit && <ExtraSettingMenu onSelect={onMenuSelect} />}
      </div>
    </>
  )
}

export const DashboardButtonsMemo = memo(DashboardButtons)
