import ResizeTable from 'components/common/table/ResizeTable'
import { forwardRef, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { DashboardExtraTemplateVariable, EventLogsConfig } from '@sentio/service/web/protos/service.pb'
import { EventLogEntry } from '@sentio/service/common'
import { isString } from 'lodash'
import { logColumnToTableColumn, parseObsoleteState } from './atoms'
import { LogTableEmpty } from './NewLogTable'
import { useLogs } from 'lib/data/use-logs'
import useTimeRange from 'lib/data/use-timerange'
import { useResizeDetector } from 'react-resize-detector'
import { DefaultShowFacets, defaultColumns } from 'components/logs/LogConstants'
import { eventlogEntry } from 'components/dashboard/atoms'
import { useSetAtom } from 'jotai'
import { QueryRangeContext } from '../../lib/context/query-range-context'
import { NumberFormat } from '../../lib/number'

const NF = NumberFormat({ notation: 'compact', maximumSignificantDigits: 3 })

interface Props {
  projectId?: string
  config?: EventLogsConfig
  updateRowCount?: (count: string) => void
  variables?: { [p: string]: DashboardExtraTemplateVariable }
  panelId?: string
}

export const LogChart = forwardRef<HTMLDivElement, Props>(
  ({ config, projectId, updateRowCount, variables, panelId }: Props, ref) => {
    const { startTime, endTime, tz } = useTimeRange()
    const query = config?.query || ''
    const columnStates = useMemo(() => {
      return config?.columnsConfig?.state ? parseObsoleteState(config.columnsConfig.state) : {}
    }, [config?.columnsConfig?.state])
    const [sorting, setSorting] = useState<any>()
    const { isShare, shareId, shareProject } = useContext(QueryRangeContext) || {}

    const setShowLogEntry = useSetAtom(eventlogEntry)

    const sorts = useMemo(() => {
      if (sorting) {
        return sorting.map((sorting) => {
          return {
            field: sorting.id,
            desc: sorting.desc
          }
        })
      }
      if (columnStates.sorting?.length) {
        return columnStates.sorting.map((sorting) => {
          return {
            field: sorting.id,
            desc: sorting.desc
          }
        })
      }
      return [{ field: DefaultShowFacets.Timestamp, desc: true }]
    }, [columnStates?.sorting, sorting])

    const externalProject = useMemo(() => {
      if (config?.sourceName) {
        const [ownerName, slug] = config.sourceName.split('/')
        if (ownerName && slug) {
          return {
            ownerName,
            slug
          }
        }
      }
    }, [config?.sourceName])

    const {
      data,
      error: searchError,
      fetchNextPage,
      isLoadingMore,
      isReachingEnd
    } = useLogs(
      projectId,
      query?.trim() || '',
      startTime,
      endTime,
      sorts,
      variables,
      tz,
      isShare ? { shareId, project: shareProject, panelId } : undefined,
      externalProject?.ownerName,
      externalProject?.slug,
      externalProject ? true : false
    )

    const { columns, rows, total } = useMemo(() => {
      const columns: any[] = []
      const logsColumns = config?.columnsConfig?.columns || []
      logsColumns.forEach((col) => {
        const targetCol = defaultColumns.find((c) => c.id === col.id)
        if (targetCol) {
          columns.push({
            ...logColumnToTableColumn(col),
            ...targetCol
          })
        } else {
          columns.push(logColumnToTableColumn(col))
        }
      })
      const rows = (data || []).reduce((acc, response) => {
        return acc.concat(response?.entries || [])
      }, [] as EventLogEntry[])
      let total = data ? data[0]?.total || 0 : 0
      total = isString(total) ? parseInt(total) : total
      return { columns, rows, total }
    }, [data, config?.columnsConfig?.columns])

    const hasData = total > 0 || isLoadingMore
    const { ref: containerRef, height, width } = useResizeDetector()

    useEffect(() => {
      let total = 'no'
      if (data && data[data.length - 1]) {
        total = data[data.length - 1].total ? NF.format(parseInt(data[data.length - 1].total)) : 'no'
      } else {
        total = 'no'
      }
      updateRowCount?.(total)
    }, [data?.[data.length - 1]?.total, updateRowCount])

    const onStateChange = useCallback((state) => {
      setSorting(state.sorting)
    }, [])

    return (
      <div ref={containerRef} className="h-full w-full px-2">
        {!hasData && <LogTableEmpty />}
        {hasData && (
          <ResizeTable
            columnResizeMode="onChange"
            onFetchMore={fetchNextPage}
            isFetching={isLoadingMore}
            hasMore={!isReachingEnd}
            columns={columns}
            data={rows}
            height={height}
            allowSort
            allowResizeColumn
            state={columnStates as any}
            ref={ref}
            allowEditColumn={false}
            manualSorting
            onStateChange={onStateChange}
            minWidth={width !== undefined && width > 60 ? width - 60 : undefined}
            onClick={(rowData) => {
              setShowLogEntry(rowData.original as EventLogEntry)
            }}
          />
        )}
      </div>
    )
  }
)

LogChart.displayName = 'LogChart'
