import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import classNames from 'lib/classnames'

interface Props {
  content: string
  className?: string
}

export function Markdown({ content, className }: Props) {
  return (
    <ReactMarkdown
      className={classNames('prose prose-primary dark:prose-invert', className)}
      remarkPlugins={[gfm]}
      components={{
        em: 'strong',
        pre: ({ node, ...props }) => <pre {...props} className="bg-gray-50 p-0" />,
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '')
          return !inline && match ? (
            <SyntaxHighlighter language={match[1]} PreTag="div" {...props}>
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          )
        }
      }}
    >
      {content}
    </ReactMarkdown>
  )
}
