import { connect, disconnect } from 'echarts/core'
import { Responsive } from 'react-grid-layout'
import {
  Dashboard,
  DashboardLayoutsLayout,
  DashboardResponsiveLayouts,
  Panel as PanelType,
  Panel
} from 'gen/service/web'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isEqual, map, mapValues, pick, pickBy, sortBy } from 'lodash'
import { FullScreenPanel } from './FullScreenPanel'
import { DashboardPanelMemo } from './DashboardPanel'
import classNames from 'lib/classnames'
import { useFirstMount } from 'lib/util/use-first-mount'
import { NotePanel } from '../note/NotePanel'
import { isMobile } from 'lib/mobile'
import mixpanel from 'mixpanel-browser'
import { getProjectUrl, useProject } from '../../lib/data/use-project'
import { useResizeDetector } from 'react-resize-detector'

const MIN_GRID_W = 2
const ResponsiveGridLayoutProps: React.ComponentProps<typeof Responsive> = {
  style: { width: 'calc(100% - 1rem)' },
  cols: { md: 12, /* sm: 6, xs: 4,*/ xxs: 1 },
  breakpoints: { md: 768, xxs: 0 },
  rowHeight: 60,
  margin: [16, 16],
  draggableHandle: '.draggableHandle',
  draggableCancel: '.nonDraggable',
  useCSSTransforms: false,
  measureBeforeMount: true,
  verticalCompact: true
}

function bottom(layouts): number {
  let max = 0,
    bottomY
  for (const b in layouts) {
    const layout = layouts[b]
    for (let i = 0, len = layout.length; i < len; i++) {
      bottomY = layout[i].y + layout[i].h
      if (bottomY > max) max = bottomY
    }
  }
  return max
}

function toServerLayoutsFormat(layouts: { [key: string]: DashboardLayoutsLayout[] }) {
  return mapValues(layouts, (layout) => ({ layouts: layout.map((l) => pick(l, ['i', 'x', 'y', 'w', 'h'])) }))
}

interface Props {
  dashboard: Dashboard
  allowEdit: boolean
  onRemovePanel?: (id: string) => void
  onEditPanel?: (panel: Panel) => void
  onLayoutChanged?: (layout: DashboardResponsiveLayouts) => void
  onClonePanel?: (id: string) => void
  onNewPanel?: () => void
  saving?: boolean
  emptyHint?: React.ReactNode
  disableRefresh?: boolean
  loadAll?: boolean
  allowClick?: boolean
}

function DashboardComponent({
  dashboard,
  allowEdit,
  onRemovePanel,
  onEditPanel,
  onLayoutChanged,
  onClonePanel,
  saving,
  emptyHint,
  disableRefresh,
  loadAll,
  allowClick = true
}: Props) {
  const [currentBreakpoint, setCurrentBreakpoint] = useState('md')
  const [fullScreenPanel, setFullScreenPanel] = useState<PanelType>()
  const isFristMount = useFirstMount()
  const ignoreLayoutChange = useRef(true)
  const { project } = useProject()

  const [currentLayouts, setCurrentLayouts] = useState({})

  const onUserActionStart = useCallback(() => {
    ignoreLayoutChange.current = false
  }, [])

  useEffect(() => {
    if (project) {
      mixpanel.track('Open Dashboard', { project: getProjectUrl(project) })
    }
  }, [project?.id])

  useEffect(() => {
    if (!dashboard?.layouts?.responsiveLayouts) {
      setCurrentLayouts({})
      return
    }

    const initLayouts = mapValues(
      pickBy(dashboard.layouts.responsiveLayouts || {}, (v, k) => k == 'md' || k == 'xss'),
      /**
       * 1. sort layouts by y, x to aviod load layout shift
       * 2. set minW to MIN_GRID_W for all layouts
       */
      (layout) =>
        sortBy(
          map(layout.layouts, (item) => ({
            ...item,
            minW: (item?.w || MIN_GRID_W) >= MIN_GRID_W ? MIN_GRID_W : item?.w
          })),
          ['y', 'x']
        )
    )

    setCurrentLayouts((preLayouts) => {
      if (isEqual(preLayouts, initLayouts)) {
        return preLayouts
      } else {
        ignoreLayoutChange.current = true
        return initLayouts
      }
    })
  }, [dashboard?.layouts?.responsiveLayouts])

  const onUserLayoutChange = useCallback(
    (_: any, layouts: { [key: string]: DashboardLayoutsLayout[] }) => {
      const newLayouts = toServerLayoutsFormat(layouts)
      if (isEqual(newLayouts, dashboard?.layouts?.responsiveLayouts)) {
        return
      }
      if (!ignoreLayoutChange.current) {
        onLayoutChanged?.(newLayouts)
      }
    },
    [dashboard?.layouts?.responsiveLayouts, onLayoutChanged]
  )

  useEffect(() => {
    connect('panel')
    return () => {
      disconnect('panel')
    }
  }, [dashboard.panels])

  // sort dashboard panels by layout, to avoid layout shift
  const [sortedDashboardPanels, panelToLayout] = useMemo(() => {
    const sortedPanels: PanelType[] = []
    const panelToLayout: Record<string, any> = {}
    const panels = dashboard?.panels || []
    const checkedPanels: string[] = []
    if (currentLayouts[currentBreakpoint]) {
      currentLayouts[currentBreakpoint].forEach((layout) => {
        if (panels[layout.i]) {
          sortedPanels.push(panels[layout.i])
          checkedPanels.push(layout.i)
          panelToLayout[layout.i] = layout
        }
      })
    }
    if (Object.keys(currentLayouts).length > 0 || dashboard?.layouts === null) {
      Object.values(panels).forEach((panel) => {
        if (panel.id && !checkedPanels.includes(panel.id)) {
          sortedPanels.push(panel)
          panelToLayout[panel.id] = {
            i: panel.id,
            x: 0,
            y: bottom(currentLayouts),
            w: 6,
            h: 4,
            minH: 2,
            minW: 3
          }
        }
      })
    }
    return [sortedPanels, panelToLayout]
  }, [currentLayouts, currentBreakpoint, dashboard])

  const panels = useMemo(() => {
    return sortedDashboardPanels.map((panel) => {
      return (
        <div data-testid={panel.id} className="flex" key={panel.id} data-grid={panel.id && panelToLayout[panel.id]}>
          {panel?.chart?.type === 'NOTE' ? (
            <NotePanel
              panel={panel}
              onEditPanel={onEditPanel}
              allowEdit={allowEdit}
              onRemovePanel={onRemovePanel}
              onClonePanel={onClonePanel}
            />
          ) : (
            <DashboardPanelMemo
              dashboard={dashboard}
              panel={panel}
              onEditPanel={onEditPanel}
              onRemovePanel={onRemovePanel}
              allowEdit={allowEdit}
              onFullScreen={setFullScreenPanel}
              onClonePanel={onClonePanel}
              disableRefresh={disableRefresh}
              noHide={loadAll}
              allowClick={allowClick}
            />
          )}
        </div>
      )
    })
  }, [allowEdit, dashboard, onClonePanel, onEditPanel, onRemovePanel, panelToLayout, sortedDashboardPanels])

  const { ref, width } = useResizeDetector({
    handleWidth: true,
    handleHeight: false
  })

  return (
    <div key={dashboard?.id} ref={ref}>
      {dashboard.panels && Object.values(dashboard.panels).length === 0 && allowEdit && emptyHint}
      <Responsive
        width={width ?? 1280}
        className={classNames('layout', isFristMount ? '' : 'animated')}
        isDraggable={allowEdit && !saving && !isMobile()}
        isResizable={allowEdit && !saving && !isMobile()}
        onLayoutChange={onUserLayoutChange}
        onBreakpointChange={setCurrentBreakpoint}
        layouts={currentLayouts}
        onDrag={onUserActionStart}
        onResize={onUserActionStart}
        {...ResponsiveGridLayoutProps}
      >
        {panels}
      </Responsive>
      {fullScreenPanel && (
        <FullScreenPanel
          dashboard={dashboard}
          projectId={dashboard?.projectId}
          panel={fullScreenPanel}
          onClose={() => setFullScreenPanel(undefined)}
        />
      )}
    </div>
  )
}

export default DashboardComponent
