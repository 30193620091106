import {
  GetChainBlockStatRequest,
  GetLogsRequest,
  GetLogsResponse,
  GetSystemStatRequest,
  ProcessorService,
  SystemStatType
} from 'gen/service/processor'
import useApi from './use-api'
import withJsonApi from './with-json-api'
import useSWRInfinite from 'swr/infinite'
import { getAccessToken } from './use-access-token'
import { useMemo } from 'react'
import { useLocalStorage } from './use-local-storage'

export function useProcessor(projectId?: string, noRefresh: boolean = false) {
  const { data, loading, mutate, token, error } = useApi(
    ProcessorService.GetProcessorStatus,
    projectId && { projectId },
    false,
    noRefresh
      ? {}
      : {
          refreshInterval: 10000
        }
  )

  const removeProcessor = async (id: string) => {
    await withJsonApi(ProcessorService.RemoveProcessor, token)({ id })
    mutate()
  }

  const activateProcessor = async (processorId: string) => {
    await withJsonApi(ProcessorService.SetActive, token)({ processorId })
    mutate()
  }

  const stopProcessor = async (processorId?: string) => {
    await withJsonApi(ProcessorService.RemoveProcessor, token)({ id: processorId })
    mutate()
  }

  const getProcessorCode = async (id?: string, historyId?: string) => {
    if (historyId) {
      return withJsonApi(ProcessorService.GetProcessorHistoryCode)({ id, historyId })
    }
    return withJsonApi(ProcessorService.GetProcessorCode)({ id })
  }

  return {
    processors: data?.processors || [],
    loading,
    removeProcessor,
    activateProcessor,
    stopProcessor,
    getProcessorCode,
    error
  }
}

const PAGE_SIZE = 500
export function useProcessorLogs(
  processorId?: string,
  query = '',
  logTypeFilters: string[] = [],
  noIntervalRefresh = false
) {
  const req: GetLogsRequest = {
    processorId,
    query,
    limit: PAGE_SIZE,
    logTypeFilters
  }
  const [adminMode] = useLocalStorage('sentio_admin_mode', false)

  const getKey = (pageIndex: number, previousPageData: GetLogsResponse) => {
    // reached the end
    if (previousPageData && (previousPageData.logs || []).length == 0) return null

    if (pageIndex === 0) return req
    console.log(pageIndex, previousPageData.after)
    return { ...req, after: previousPageData.after }
  }

  const fetcher = async (req: GetLogsRequest) => {
    const accessToken = await getAccessToken()
    const otherHeaders = {}
    if (accessToken != 'anonymous' && adminMode) {
      otherHeaders['x-admin-mode'] = 'true'
    }
    return await ProcessorService.GetLogs(req, {
      pathPrefix: '',
      headers: {
        'content-type': 'application/json',
        authorization: accessToken == 'anonymous' ? undefined : `Bearer ${accessToken}`,
        ...otherHeaders
      } as any
    })
  }

  const { data, error, isValidating, mutate, size, setSize } = useSWRInfinite(
    getKey,
    fetcher,
    noIntervalRefresh
      ? {
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
          revalidateIfStale: false
        }
      : {
          refreshInterval: 30000
        }
  )
  const isLoadingInitialData = !data && !error
  const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined')
  const isEmpty = (data?.[0]?.logs || []).length === 0
  const isReachingEnd = isEmpty || (data && (data[data.length - 1]?.logs || []).length < PAGE_SIZE)
  const isRefreshing = isValidating && data && data.length === size

  return {
    data,
    isLoadingMore,
    isReachingEnd,
    isRefreshing,
    mutate,
    fetchNextPage: () => setSize(size + 1)
  }
}

export function useProcessorHistory(processorId?: string) {
  return useApi(ProcessorService.GetProcessorUpgradeHistories, processorId && { processorId })
}

export function useProcessorCode(processorId?: string, historyId?: string) {
  return useApi(
    historyId ? ProcessorService.GetProcessorHistoryCode : ProcessorService.GetProcessorCode,
    processorId && { id: processorId, historyId },
    undefined,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  )
}

export function useProcessorSystemState(
  type: SystemStatType,
  processorId?: string,
  startTime?: string,
  endTime?: string
) {
  const req = useMemo(() => {
    if (!processorId) {
      return null
    }
    const req: GetSystemStatRequest = {
      processorId,
      type
    }
    if (startTime) {
      req.startTime = startTime
    }
    if (endTime) {
      req.endTime = endTime
    }
    return req
  }, [processorId, startTime, endTime, type])
  return useApi(ProcessorService.GetSystemStat, req, undefined, {
    keepPreviousData: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false
  })
}

export function useProcessorChainStat(processorId?: string, startTime?: string, endTime?: string) {
  const req = useMemo<GetChainBlockStatRequest | null>(() => {
    if (!processorId || !startTime || !endTime) {
      return null
    }
    const req: GetChainBlockStatRequest = {
      processorId
    }
    if (startTime) {
      req.startTime = startTime
    }
    if (endTime) {
      req.endTime = endTime
    }
    return req
  }, [processorId, startTime, endTime])
  return useApi(ProcessorService.GetChainBlockStat, req, undefined, {
    keepPreviousData: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: true
  })
}
