import { ArrowTopRightOnSquareIcon, ArrowDownTrayIcon, CameraIcon, CommandLineIcon } from '@heroicons/react/24/outline'
import { PopupMenuButton } from './PopupMenuButton'
import { MouseEvent, useMemo } from 'react'
import { isProjectFreeTier } from 'lib/data/atoms'
import { useAtomValue } from 'jotai'
import { produce } from 'immer'
import { ExportCurlLinkHint } from 'components/tier/LimitMessages'
import useUser from 'lib/data/use-user'

const DefaultMenuItems: IMenuItem[][] = [
  [
    {
      label: 'Take a snapshot',
      icon: <CameraIcon className="mr-2 w-4" />,
      key: 'snapshot'
    }
  ],
  [
    {
      key: 'png',
      label: 'Export as PNG',
      icon: <ArrowTopRightOnSquareIcon className="mr-2 w-4" />
    },
    {
      key: 'svg',
      label: 'Export as SVG',
      icon: <ArrowDownTrayIcon className="mr-2 w-4" />
    },
    {
      key: 'csv',
      label: 'Export as CSV',
      icon: <ArrowDownTrayIcon className="mr-2 w-4" />
    }
  ],
  [
    {
      label: 'Export as cURL',
      icon: <CommandLineIcon className="mr-2 w-4" />,
      key: 'curl',
      disabledHint: <ExportCurlLinkHint />
    }
  ]
]

interface Props {
  allowEdit?: boolean
  onSelect: (key: string, event: MouseEvent) => void
}

export function ExportChartMenu({ allowEdit, onSelect }: Props) {
  const isFreeTier = useAtomValue(isProjectFreeTier)
  const { user } = useUser()
  const menuItems = useMemo(() => {
    return produce(DefaultMenuItems, (draft) => {
      draft[0][0].disabled = !allowEdit
      draft[draft.length - 1][0].disabled = isFreeTier || !user || !allowEdit
    })
  }, [isFreeTier, user])

  return (
    <PopupMenuButton
      items={menuItems}
      onSelect={onSelect}
      ariaLabel={'export'}
      buttonIcon={<ArrowTopRightOnSquareIcon className="w-4" />}
      buttonClassName="cursor-pointer"
    />
  )
}
