import React from 'react'
import Image from 'next/image'
import Button from 'components/common/buttons/NewButton'
import Link from 'next/link'
import { useRouter } from 'next/router'
import BarLoading from '../common/util/BarLoading'

interface Props {
  data?: any
}

export const ErrorChart = React.memo(function ErrorChart({ data }: Props) {
  const router = useRouter()
  const { slug, owner } = router.query
  let hintMessage: React.ReactNode
  if (data?.code === 5) {
    hintMessage = (
      <span>
        No active processor found
        <br /> Check the
        <Link href={`/${owner}/${slug}/datasource`}>
          <Button role="link">datasource</Button>
        </Link>
        page for more details.
      </span>
    )
  } else if (data?.status === 429) {
    return (
      <BarLoading
        className="dark:bg-sentio-gray-100 absolute w-full bg-white"
        hint="Too many simultaneous requests, retrying later..."
        width={100}
      />
    )
  } else {
    hintMessage = data?.message ?? 'Something went wrong'
  }
  return (
    <div className="flex h-full w-full items-center">
      <div className="w-full space-y-4 text-center">
        <Image className="mx-auto" src="/gray-logo.png" width={64} height={64} alt="gray logo" />
        <div className="text-gray font-icontent text-icontent">{hintMessage}</div>
      </div>
    </div>
  )
})
