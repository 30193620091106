import { ChartConfig, ChartDataSourceType, Dashboard, Panel } from 'gen/service/web'
import ChartComponent from '../charts/Chart'
import { useTimeRangeWithOverrideConfig } from 'lib/data/use-timerange'
import { useTemplateValues } from 'lib/data/use-template-values'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import TimeRangePicker from 'components/timerange/TimeRangePicker'
import { ObservabilityService } from 'gen/service/observability'
import { useApiMutate } from 'lib/data/use-api'
import { usePanelQueryData } from '../../lib/data/use-panel-query-data'
import { LogChart } from 'components/logs/LogChart'
import { MetricsQueryResponse } from '../../gen/service/observability'
import SqlChart from '../charts/SqlChart'
import { SyncExecuteSQLResponse } from '@sentio/service/analytic'

interface Props {
  projectId?: string
  panel: Panel
  onClose: () => void
  dashboard: Dashboard
  controls?: boolean
  onChangeConfig?: (config: ChartConfig) => void
}

export function FullScreenPanel({ panel, projectId, onClose, onChangeConfig, controls, dashboard }: Props) {
  const { chart } = panel
  const { startTime, endTime, setTimeRange, tz } = useTimeRangeWithOverrideConfig(
    chart?.config?.timeRangeOverride,
    undefined,
    `dashboard.${panel?.dashboardId}`
  )
  const { templateValues } = useTemplateValues(dashboard?.extra?.templateVariables)

  const { data, loading, ready } = usePanelQueryData({
    projectId,
    panel,
    startTime,
    endTime,
    tz,
    templateValues,
    refreshInterval: 30000,
    enabled: true
  })

  const mutateApi = useApiMutate()

  const refresh = () => {
    mutateApi(ObservabilityService.QueryRange)
  }

  let contentNode: React.ReactNode

  switch (chart?.datasourceType) {
    case ChartDataSourceType.EVENTS:
      contentNode = (
        <LogChart
          projectId={dashboard.projectId}
          config={chart.eventLogsConfig}
          variables={dashboard?.extra?.templateVariables}
        />
      )
      break
    case ChartDataSourceType.SQL:
      contentNode = (
        <SqlChart
          style={{ height: undefined }}
          data={data as SyncExecuteSQLResponse}
          loading={loading}
          config={chart.config}
          chartType={chart.type}
        />
      )
      break
    default:
      contentNode = (
        <ChartComponent
          style={{ height: undefined }}
          data={data as MetricsQueryResponse}
          loading={ready && loading}
          startTime={startTime}
          endTime={endTime}
          onSelectTimeRange={setTimeRange}
          controls={controls}
          config={chart?.config}
          chartType={chart?.type}
          onChangeConfig={onChangeConfig}
          tz={tz}
        />
      )
  }

  return (
    <Dialog open={true} onClose={onClose} className="relative z-10">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="bg-text-background fixed inset-0 m-4 overflow-auto">
        <Dialog.Panel
          className="dark:bg-sentio-gray-100 grid h-full bg-white"
          style={{ gridTemplateRows: 'min-content 1fr' }}
        >
          <div className="flex flex-wrap items-center justify-stretch gap-y-1 border-b px-4 py-2 sm:justify-between">
            <div className="sm:flex-0 flex flex-1 justify-between">
              <h1>{panel.name}</h1>
              <button className="ml-3 text-gray-400 hover:text-gray-500 sm:hidden" type="button" onClick={onClose}>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="flex items-center">
              <TimeRangePicker
                onChange={setTimeRange}
                startTime={startTime}
                endTime={endTime}
                tz={tz}
                onRefresh={refresh}
              />
              <button
                className="hidden text-gray-400 hover:text-gray-500 sm:ml-3 sm:inline"
                type="button"
                onClick={onClose}
              >
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="min-h-0 max-w-full overflow-auto p-2">{contentNode}</div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
