import * as React from 'react'
import { useResponsiveWidth } from 'lib/use-responsive-width'
import classNames from 'lib/classnames'
import { NewLayoutFlight, useFlight } from 'lib/data/use-flight'

interface Props {
  id?: string
  children?: React.ReactNode
  title?: string
  buttons?: React.ReactNode
  addons?: React.ReactNode
  titleExtra?: React.ReactNode
  noDivider?: boolean
  allowEdit?: boolean
  refreshProps?: {
    updateAt?: string
    onRefresh?: () => void
  }
  containerStyle?: React.CSSProperties
}

export function Panel({
  id,
  title,
  children,
  buttons,
  addons,
  noDivider,
  allowEdit,
  titleExtra,
  containerStyle
}: Props) {
  const { responsiveWidth, ref } = useResponsiveWidth()
  const { isFlightEnabled } = useFlight()
  const isNewLayout = isFlightEnabled(NewLayoutFlight)
  return (
    <div
      id={id}
      aria-label={title && `Panel ${title}`}
      className={classNames(
        !noDivider && 'dark:divide-border-color/50 divide-y',
        isNewLayout ? 'border-border-color dark:border-border-color/50 border' : '',
        'dark:bg-sentio-gray-100 flex h-full w-full flex-col rounded-lg bg-white p-px'
      )}
      ref={ref}
      style={containerStyle}
    >
      <div
        className={classNames(
          allowEdit ? '' : '',
          'group flex h-8 items-center justify-between px-2 py-1',
          responsiveWidth
        )}
      >
        <div className="flex flex-1 items-center truncate">
          <h3
            className={classNames(
              'draggableHandle cursor-move truncate',
              isNewLayout
                ? 'text-ilabel font-ilabel shrink-1 dark:text-text-foreground grow-0 select-none group-hover:select-auto'
                : ''
            )}
          >
            {title}
          </h3>
          <div className="nonDraggable shrink-1 grow-0 overflow-hidden">{addons}</div>
          {titleExtra}
          <div className="draggableHandle h-4 flex-1 cursor-move"></div>
        </div>
        <div className="nonDraggable invisible flex min-w-fit group-hover:visible">{buttons}</div>
      </div>
      <div className="h-[calc(100%-2em)] flex-1">{children}</div>
    </div>
  )
}
