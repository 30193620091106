export const MoveLeftIcon = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_6869_7300)">
      <path
        d="M2.66666 8H9.33332"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66666 8L5.33332 10.6667"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66669 7.9987L5.33335 5.33203"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 2.66797V13.3346"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6869_7300">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const MoveRightIcon = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_6869_7309)">
      <path
        d="M13.3333 8H6.66666"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 8L10.6667 10.6667"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3334 7.9987L10.6667 5.33203"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66669 2.66797V13.3346"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6869_7309">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const RenameIcon = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8 13.3281H14" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M11 2.33609C11.2652 2.07087 11.6249 1.92188 12 1.92188C12.1857 1.92188 12.3696 1.95845 12.5412 2.02953C12.7128 2.1006 12.8687 2.20477 13 2.33609C13.1313 2.46741 13.2355 2.62331 13.3066 2.79489C13.3776 2.96647 13.4142 3.15037 13.4142 3.33609C13.4142 3.52181 13.3776 3.7057 13.3066 3.87728C13.2355 4.04886 13.1313 4.20477 13 4.33609L4.66667 12.6694L2 13.3361L2.66667 10.6694L11 2.33609Z"
      stroke="currentColor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const DeleteIcon = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_6869_7344)">
      <path
        d="M2.66669 4.66797H13.3334"
        stroke="currentColor"
        strokeWidth="1.02222"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66669 7.33203V11.332"
        stroke="currentColor"
        strokeWidth="1.02222"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33331 7.33203V11.332"
        stroke="currentColor"
        strokeWidth="1.02222"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33331 4.66797L3.99998 12.668C3.99998 13.0216 4.14046 13.3607 4.3905 13.6108C4.64055 13.8608 4.97969 14.0013 5.33331 14.0013H10.6666C11.0203 14.0013 11.3594 13.8608 11.6095 13.6108C11.8595 13.3607 12 13.0216 12 12.668L12.6666 4.66797"
        stroke="currentColor"
        strokeWidth="1.02222"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 4.66667V2.66667C6 2.48986 6.07024 2.32029 6.19526 2.19526C6.32029 2.07024 6.48986 2 6.66667 2H9.33333C9.51014 2 9.67971 2.07024 9.80474 2.19526C9.92976 2.32029 10 2.48986 10 2.66667V4.66667"
        stroke="currentColor"
        strokeWidth="1.02222"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6869_7344">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
