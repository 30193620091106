import { useProjectVersions } from '../../lib/data/use-project-versions'
import { useMemo } from 'react'
import { useProcessor } from '../../lib/data/use-processor'
import { VscError, VscWarning } from 'react-icons/vsc'
import { PopoverTooltip } from '../common/tooltip/PopoverTooltip'
import { ChainStateText, getChainProgress } from './ProcessorChains'

type Props = {
  projectId?: string
  showVersion?: boolean
}

export function ProcessorStatusIcon({ projectId, showVersion }: Props) {
  const { currentProcessor, currentVersion } = useProjectVersions(projectId)
  const { processors } = useProcessor(projectId, true)
  const processor = processors.find((p) => p.processorId === currentProcessor)

  return useMemo(() => {
    for (const state of processor?.states || []) {
      if (state.status?.state !== 'PROCESSING_LATEST') {
        const text =
          `Chain ${state.chainId} is in ${ChainStateText[state.status?.state ?? '']} state\n` +
          `Chart data may be outdated or incomplete.`
        const chainProgress = getChainProgress(state) * 100
        return (
          <PopoverTooltip
            icon={
              <span className={'flex items-center text-xs text-gray-500'}>
                <VscWarning className="z-10 h-4 w-4 cursor-pointer text-amber-400" />
                {chainProgress.toFixed(0)}%
              </span>
            }
            text={text}
          />
        )
      }
    }

    if (processor?.processorStatus?.state === 'ERROR') {
      const text = `Processor is in error state. Chart\n data may be outdated or incomplete.`
      return <PopoverTooltip icon={<VscError className="h-4 w-4 cursor-pointer text-red-400" />} text={text} />
    }

    if (showVersion && currentVersion) {
      return <span className="ml-1 block truncate text-xs font-medium text-green-500">{`V${currentVersion}`}</span>
    }

    return null
  }, [processor, currentVersion])
}
