import Button from '../common/buttons/NewButton'
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { customAlphabet, urlAlphabet } from 'nanoid'
import { Chart } from 'gen/service/web'
import { MetricsQueryResponse } from 'gen/service/observability'
import ChartComponent from '../charts/Chart'
import { useSnapshot } from 'lib/data/use-snapshot'
import { Snapshot } from 'gen/service/web'
import { DateTimeValue, toDayjs } from 'lib/time'
import { useRouter } from 'next/router'

interface Props {
  data?: MetricsQueryResponse
  chart: Chart
  open: boolean
  onClose: () => void
  defaultName?: string
  startTime: DateTimeValue
  endTime: DateTimeValue
  projectId: string
}

const nanoid = customAlphabet(urlAlphabet, 12)

export function SnapshotDialog({ data, chart, open, onClose, defaultName, startTime, endTime, projectId }: Props) {
  const [snapshotId, setSnapshotId] = useState<string>('')
  const [name, setName] = useState<string | undefined>(defaultName)
  const { saveSnapshot } = useSnapshot()
  const router = useRouter()

  useEffect(() => {
    if (open) {
      setSnapshotId(nanoid())
    }
  }, [open])

  const onSubmit = async () => {
    await saveSnapshot({
      id: snapshotId,
      chart,
      data,
      name,
      startTime: toDayjs(startTime).toISOString(),
      endTime: toDayjs(endTime).toISOString(),
      projectId: projectId
    } as Snapshot).then(() => {
      router.push(`/snapshots/${snapshotId}`)
    })
  }

  const link = `${window.location.origin}/snapshots/${snapshotId}`

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity dark:bg-gray-200/50" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="dark:bg-sentio-gray-100 relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <Dialog.Title as="h3" className="text-text-foreground text-lg font-medium leading-6">
                  Create a snapshot
                </Dialog.Title>
                <form action="app/components/dialog#" className="relative mt-2">
                  <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                    <div>
                      <label
                        htmlFor="panel-name"
                        className="required text-text-foreground block text-sm font-medium sm:mt-px sm:pt-2"
                      >
                        Title
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        type="text"
                        required={true}
                        name="panel-name"
                        id="panel-name"
                        onChange={(e) => setName(e.target.value)}
                        value={name || ''}
                        className="focus:border-primary-500 focus:ring-primary-500 block w-full  rounded-md border-gray-300 shadow-sm sm:text-sm"
                      />
                    </div>
                  </div>

                  {open && (
                    <ChartComponent
                      style={{ height: 200 }}
                      data={data}
                      loading={false}
                      config={chart.config}
                      chartType={chart.type}
                    />
                  )}

                  <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                    <div>
                      <label htmlFor="link" className="text-text-foreground block text-sm font-medium sm:mt-px sm:pt-2">
                        Snapshot Link
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        type="text"
                        name="link"
                        id="link"
                        readOnly
                        value={link}
                        className="focus:border-primary-500 block w-full rounded-md border-gray-300  bg-gray-100 shadow-sm focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </form>
                <div className="mt-5 gap-3 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <Button role="primary" onClick={onSubmit}>
                    Create
                  </Button>
                  <Button onClick={onClose}>Close</Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
