import { CSSProperties, forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Row,
  Cell,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender,
  ColumnResizeMode,
  TableState
} from '@tanstack/react-table'
import classNames from 'lib/classnames'
import { HiOutlineSortDescending, HiOutlineSortAscending, HiChevronDown } from 'react-icons/hi'
import { debounce, isEqual } from 'lodash'
import { PopupMenuButton } from 'components/menu/PopupMenuButton'
import { ColumnOrderState } from '@tanstack/react-table'
import { MoveLeftIcon, MoveRightIcon, RenameIcon, DeleteIcon } from './Icons'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

interface Props {
  data: any
  columns: ColumnDef<any>[]
  columnResizeMode: ColumnResizeMode
  onClick?: (row: Row<any>, cell: Cell<any, any>) => void
  height?: CSSProperties['height']
  onFetchMore?: () => void
  hasMore?: boolean
  isFetching?: boolean

  state?: Partial<TableState>
  onStateChange?: (state: TableState) => void

  //sort
  allowSort?: boolean
  manualSorting?: boolean // server-side sorting

  //resize
  allowResizeColumn?: boolean

  //edit column
  allowEditColumn?: boolean
  onColumnRename?: (data: ColumnDef<any>) => void
  onColumnRemove?: (data: ColumnDef<any>) => void

  minSize?: number
  minWidth?: number

  rowClassNameFn?: (row: Row<any>) => string
}

function onPreventClick(e) {
  e.stopPropagation()
}

const _ResizeTable = forwardRef<HTMLDivElement, Props>(function _ResizeTable(
  {
    data,
    columns,
    columnResizeMode,
    onClick,
    height,
    onFetchMore,
    hasMore,
    isFetching,
    allowSort,
    allowEditColumn,
    allowResizeColumn,
    state = {},
    onStateChange,
    onColumnRemove,
    onColumnRename,
    minSize,
    manualSorting,
    minWidth,
    rowClassNameFn
  }: Props,
  tableContainerRef
) {
  const adjustedColumns = useMemo(() => {
    let totalWidth = 0
    const newColumns = columns.map((c) => {
      const item = Object.assign({ minSize }, c)
      totalWidth += item.size || item.minSize || 0
      return item
    })
    if (minWidth && totalWidth < minWidth) {
      const ratio = minWidth / totalWidth
      newColumns.forEach((c) => {
        if (c.size) {
          c.size = Math.floor(c.size * ratio)
        } else if (c.minSize) {
          c.size = Math.floor(c.minSize * ratio)
        }
      })
    }
    return newColumns
  }, [columns, minSize, minWidth])
  const table = useReactTable({
    data,
    columns: adjustedColumns,
    // onSortingChange: changeSorting,
    columnResizeMode: columnResizeMode,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: allowSort ? getSortedRowModel() : undefined,
    state,
    manualSorting
  })

  const [tableState, setTableState] = useState<TableState>(table.initialState)
  table.setOptions((prev) => ({
    ...prev,
    state: tableState,
    onStateChange: setTableState
  }))

  useEffect(() => {
    // compare the state and set the table state
    if (state) {
      setTableState((prev) => {
        const newState = {
          ...prev,
          ...state
        }
        return isEqual(prev, newState) ? prev : newState
      })
    }
  }, [state])
  const debounceStateChange = useMemo(() => {
    if (!onStateChange) return undefined
    return debounce(onStateChange, 500, {
      // trailing: true,
    })
  }, [onStateChange])
  useEffect(() => {
    if (isEqual(table.initialState, tableState)) return
    debounceStateChange?.(tableState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceStateChange, tableState])

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useMemo(() => {
    return debounce((containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement
        //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (scrollHeight - scrollTop - clientHeight < 300 && !isFetching && hasMore) {
          onFetchMore?.()
        }
      }
    }, 500)
  }, [onFetchMore, isFetching, hasMore])

  return (
    <div
      className="overflow-auto"
      style={height ? { height } : undefined}
      ref={tableContainerRef}
      onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
    >
      <table
        className="w-fit"
        {...{
          style: {
            width: table.getCenterTotalSize()
          }
        }}
      >
        <thead className="dark:bg-sentio-gray-100 sticky top-0 z-[1] bg-white">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="relative flex w-fit cursor-pointer items-center border-b">
              {headerGroup.headers.map((header, i) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width: header.getSize()
                  }}
                  className="text-ilabel group/th blinked dark:hover:!bg-sentio-gray-300 dark:bg-sentio-gray-100 text-text-foreground hover:!bg-primary-50 relative flex items-center whitespace-nowrap bg-white px-2 py-2 text-left font-semibold"
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <span className="flex flex-1">
                    <span className="flex-1">
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </span>
                    {header.column.getCanSort() && allowSort ? (
                      <span
                        className={classNames(
                          header.column.getIsSorted()
                            ? 'hover:text-text-foreground visible hover:bg-gray-200'
                            : 'invisible',
                          'ml-2 flex-none rounded px-1 py-0.5 text-gray-600 group-hover:visible group-focus:visible',
                          'inline-block cursor-pointer',
                          'shrink-0'
                        )}
                      >
                        {header.column.getIsSorted() ? (
                          header.column.getIsSorted() == 'desc' ? (
                            <HiOutlineSortDescending className="h-4 w-4" />
                          ) : (
                            <HiOutlineSortAscending className="h-4 w-4" />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    ) : null}
                  </span>
                  {allowEditColumn !== false && (
                    <span className="invisible inline-block group-hover/th:visible" onClick={onPreventClick}>
                      <PopupMenuButton
                        buttonClassName="align-text-bottom"
                        onSelect={(commandKey: string) => {
                          const colOrder = headerGroup.headers.map((item) => (item as any)?.id)
                          switch (commandKey) {
                            case 'reorder.left':
                              table.setColumnOrder(reorder(colOrder, i, i - 1) as ColumnOrderState)
                              break
                            case 'reorder.right':
                              table.setColumnOrder(reorder(colOrder, i, i + 1) as ColumnOrderState)
                              break
                            case 'delete':
                              onColumnRemove?.(header.column.columnDef)
                              break
                            default:
                              console.log(commandKey, 'is not applied')
                          }
                        }}
                        buttonIcon={<HiChevronDown className="icon mr-2" />}
                        items={[
                          [
                            {
                              key: 'reorder.left',
                              label: 'Move column left',
                              icon: <MoveLeftIcon className="mr-2" />,
                              disabled: i === 0
                            },
                            {
                              key: 'reorder.right',
                              label: 'Move column right',
                              icon: <MoveRightIcon className="mr-2" />,
                              disabled: i === headerGroup.headers.length - 1
                            }
                          ],
                          ...(onColumnRename
                            ? [
                                [
                                  {
                                    key: 'rename',
                                    label: 'Rename column',
                                    icon: <RenameIcon className="mr-2" />
                                  }
                                ]
                              ]
                            : []),
                          ...(!onColumnRemove
                            ? []
                            : [
                                [
                                  {
                                    key: 'delete',
                                    label: 'Remove column',
                                    icon: <DeleteIcon className="mr-2" />,
                                    status: 'danger'
                                  }
                                ]
                              ])
                        ]}
                      />
                    </span>
                  )}
                  {header.column.getCanResize() ? (
                    <div
                      onMouseDown={header.getResizeHandler()}
                      onTouchStart={header.getResizeHandler()}
                      className={classNames(
                        // header.column.getIsResizing() ? 'bg-primary-200 opacity-100' : '',
                        `text-md hover:bg-primary-200/50 absolute right-0 top-0 inline-block flex
                          h-full w-2 cursor-col-resize touch-none select-none items-center text-gray-400`
                      )}
                      style={{
                        transform:
                          columnResizeMode === 'onEnd' && header.column.getIsResizing()
                            ? `translateX(${table.getState().columnSizingInfo.deltaOffset}px)`
                            : ''
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      ⋮
                    </div>
                  ) : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table?.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className={classNames(
                'hover:!bg-primary-50 dark:hover:!bg-sentio-gray-300 blinked group flex w-fit items-center border-b',
                onClick ? 'cursor-pointer' : '',
                rowClassNameFn ? rowClassNameFn(row) : ''
              )}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  {...{
                    style: {
                      width: cell.column.getSize()
                    }
                  }}
                  onClick={() => onClick && onClick(row, cell)}
                  className="text-ilabel dark:text-text-foreground-secondary truncate whitespace-nowrap py-2 pl-2 text-gray-600"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
          {onFetchMore && (
            <tr>
              <td
                colSpan={table.getHeaderGroups()[0].headers.length}
                className="text-ilabel hover:bg-primary-50 cursor-pointer py-2 text-center text-gray-600"
                onClick={() => {
                  onFetchMore?.()
                }}
              >
                {hasMore ? 'Loading...' : 'No more data'}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
})

export const ResizeTable = memo(_ResizeTable)

export default ResizeTable
