import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { QueryRangeRequest } from '../../gen/service/observability'
import { SegmentationRequest, SyncExecuteSQLRequest } from '../../gen/service/analytic'
import Link from 'next/link'
import ButtonGroup from 'components/common/buttons/NewButtonGroup'
import { TbTerminal, TbBrandNodejs } from 'react-icons/tb'
import { ArrowRightIcon } from '@heroicons/react/20/solid'
import { CodeBlockWithTitle } from 'components/simple-editor/CodeBlock'
import omit from 'lodash/omit'

interface Props {
  open: boolean
  onClose: () => void
  payload:
    | QueryRangeRequest
    | SegmentationRequest
    | SyncExecuteSQLRequest
    | {
        projectOwner: string
        projectSlug: string
        query?: string
        variables?: Record<string, any>
        projectId?: string
      }

  apiUrl?: string
  headers?: Record<string, any>
}

function generateNodeCode(url: string, data: any, headers?: Record<string, any>) {
  return `const fetch = (...args) => import('node-fetch').then(({default: fetch}) => fetch(...args));

const url = '${url}';
const apiKey = '<API_KEY>';
const data = ${JSON.stringify(data, null, 2)};
${headers ? `const headers = ${JSON.stringify(headers, null, 2)};` : ''}

fetch(url, {
  method: 'POST',
  headers: {
    'api-key': apiKey,
    'Content-Type': 'application/json',${headers ? '\n    ...headers,\n' : ''}
  },
  body: JSON.stringify(data),
})
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then(data => {
    // Do something with the data
    console.log(data);
  })
  .catch(error => {
    // Handle the error
    console.error('Error:', error);
  });

`
}

function generateCurlCode(url: string, data: any, headers?: Record<string, any>) {
  return `curl -L -X POST '${url}' \\
     -H 'api-key: <API_KEY>' \\
     -H 'Content-Type: application/json' \\${
       headers
         ? '\n' +
           Object.entries(headers)
             .map(([key, value]) => `     -H '${key}: ${value}' \\`)
             .join('\n')
         : ''
     }
     --data-raw '${escapeBody(JSON.stringify(data, null, 2))}'
`
}

export function escapeBody(body) {
  if (typeof body !== 'string') return body
  return body.replace(/'/g, `'\\''`)
}

enum ExportType {
  CURL = 'curl',
  NODE = 'node'
}

export function CurlDialog({ open, onClose, payload, apiUrl, headers }: Props) {
  const data = omit(payload, ['projectSlug', 'projectOwner', 'projectId'])
  const [type, setType] = useState(ExportType.CURL)

  const url = `${origin}${apiUrl}`
  const content = generateCurlCode(url, data, headers)
  const nodeContent = generateNodeCode(url, data, headers)
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity dark:bg-gray-200/50" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="dark:bg-sentio-gray-100 relative transform overflow-hidden rounded-lg bg-white pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                <Dialog.Title as="h3" className="text-text-foreground px-4 text-lg font-medium leading-6 sm:px-6">
                  Export As
                </Dialog.Title>
                <div className="my-2 space-y-4 border-t px-4 pt-4 sm:px-6">
                  <div className="text-icontent bg-primary-50 flex w-full flex-wrap items-center justify-between gap-y-1 rounded-md px-4 py-2 font-medium">
                    <span>
                      Replace <code className="text-primary-600 dark:text-primary-800">&lt;API_KEY&gt;</code>
                      {` `}with your real API key.
                    </span>
                    <Link href="/profile/apikeys" target="_blank">
                      <div className="border-primary-400 text-primary-600 dark:text-primary-800 dark:border-primary-600 hover:bg-primary-100 active:bg-primary-200 flex flex-row items-center justify-center gap-1 rounded-md border border-solid pb-[7px] pl-2.5 pr-2.5 pt-[7px]">
                        <div className="text-icontent text-left font-medium">Create a new API Key</div>
                        <ArrowRightIcon className="h-4 w-4" />
                      </div>
                    </Link>
                  </div>
                  <div className="flex items-center gap-2">
                    <ButtonGroup
                      value={type}
                      onChange={setType}
                      buttons={[
                        {
                          label: 'Shell',
                          value: ExportType.CURL,
                          icon: <TbTerminal className="mr-2 h-4 w-4" />
                        },
                        {
                          label: 'Node',
                          value: ExportType.NODE,
                          icon: <TbBrandNodejs className="mr-2 h-4 w-4" />
                        }
                      ]}
                    />
                  </div>
                  {type === ExportType.CURL && (
                    <CodeBlockWithTitle
                      value={content}
                      showLineNumbers
                      language="typescript"
                      title="Shell"
                      icon={<TbTerminal className="mr-2 inline-block h-4 w-4" />}
                    />
                  )}
                  {type === ExportType.NODE && (
                    <CodeBlockWithTitle
                      value={nodeContent}
                      showLineNumbers
                      language="typescript"
                      title="Nodejs"
                      icon={<TbBrandNodejs className="mr-2 inline-block h-4 w-4" />}
                    />
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
