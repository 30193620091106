import { TrashIcon, Cog6ToothIcon, Square2StackIcon, ClipboardDocumentIcon } from '@heroicons/react/24/outline'
import { PopupMenuButton } from './PopupMenuButton'
import { MouseEvent } from 'react'

const menuItems = [
  [
    {
      label: 'Clone',
      icon: <Square2StackIcon className="mr-2 w-4" />,
      key: 'clone'
    },
    {
      label: 'Copy configuration',
      icon: <ClipboardDocumentIcon className="mr-2 w-4" />,
      key: 'copy'
    }
  ],
  [
    {
      label: 'Delete',
      key: 'delete',
      status: 'danger',
      icon: <TrashIcon className="mr-2 w-4" />
    }
  ]
] as IMenuItem[][]

interface Props {
  onSelect: (key: string, event: MouseEvent) => void
}

export function ExtraSettingMenu({ onSelect }: Props) {
  return (
    <PopupMenuButton
      items={menuItems}
      onSelect={onSelect}
      ariaLabel={'settings'}
      buttonIcon={<Cog6ToothIcon className="w-4" />}
      buttonClassName="cursor-pointer"
    />
  )
}
