import useApi from './use-api'
import { Snapshot, WebService } from 'gen/service/web'
import withJsonApi from './with-json-api'

export function useSnapshot(snapshotId?: string) {
  const isReady = !!snapshotId

  const { data, loading, ready, error, mutate, token } = useApi(
    WebService.GetSnapshot,
    isReady
      ? {
          snapshotId,
        }
      : null,
    false
  )

  const saveSnapshot = async (snapshot: Snapshot) => {
    const response = await withJsonApi(
      WebService.SaveSnapshot,
      token
    )({
      snapshotId: snapshot.id,
      snapshot,
    })

    mutate({
      snapshot: response.snapshot,
    })
  }

  const deleteSnapshot = async (snapshotId: string) => {
    const response = await withJsonApi(
      WebService.DeleteSnapshot,
      token
    )({
      snapshotId,
    })

    mutate()
  }

  return {
    snapshot: data?.snapshot,
    saveSnapshot,
    deleteSnapshot,
    loading,
    ready,
    error,
    mutate,
  }
}
