import { useResizeDetector } from 'react-resize-detector'

const options = {
  refreshMode: 'debounce',
  refreshRate: 500,
} as any

export function useResponsiveWidth() {
  const { width, ref } = useResizeDetector(options)
  let responsiveWidth = 'lg'
  if (width) {
    if (width < 320) {
      responsiveWidth = 'xs'
    } else if (width < 640) {
      responsiveWidth = 'sm'
    } else if (width < 768) {
      responsiveWidth = 'md'
    } else if (width < 1024) {
      responsiveWidth = 'lg'
    } else if (width < 1280) {
      responsiveWidth = 'xl'
    } else {
      responsiveWidth = '2xl'
    }
  }
  return {
    width,
    responsiveWidth,
    ref,
  }
}
