import { atom } from 'jotai'
import { atomWithReducer } from 'jotai/utils'
import { ColumnDef } from '@tanstack/react-table'
import { LogFacet } from 'gen/service/analytic'
import { startCase, isEqual } from 'lodash'
import { EventLogColumn, ColumnState, ColumnStateSort } from 'gen/service/common'
import {
  AccessorKeys,
  DefaultEventLogColumns,
  ObsoleteFacets,
  defaultShowColumnCells,
  defaultColumnCell,
} from './LogConstants'

export type LogColumnAction =
  | {
      type: 'add' | 'remove' | 'update'
      payload: EventLogColumn
    }
  | {
      type: 'reset'
      payload: EventLogColumn[]
    }

const logColumnReducer = (prev: EventLogColumn[], action: LogColumnAction) => {
  if (action.type === 'add') {
    return [...prev, action.payload]
  }
  if (action.type === 'remove') {
    return prev.filter((column) => column.id !== action.payload.id)
  }
  if (action.type === 'update') {
    return prev.map((column) => {
      if (column.id === action.payload.id) {
        return action.payload
      }
      return column
    })
  }
  if (action.type === 'reset') {
    return action.payload.map((item) => {
      if (item.id && ObsoleteFacets[item.id]) {
        return {
          ...item,
          id: ObsoleteFacets[item.id],
          accessorKey: AccessorKeys[ObsoleteFacets[item.id]],
        }
      }
      return item
    })
  }
  return prev
}

export const logsColumns = atomWithReducer<EventLogColumn[], LogColumnAction>([], logColumnReducer)
export const extraColumnKeys = atom((get) => get(logsColumns).map((column) => column.id))

export const facetToLogColumn = (facet: LogFacet): EventLogColumn => {
  const { name, path } = facet
  if (path && DefaultEventLogColumns[path]) {
    return DefaultEventLogColumns[path]
  }

  return {
    name: name || '',
    id: facet.path || '',
    size: 100,
    enableHiding: true,
    enableResizing: true,
    enableSorting: true,
  } as EventLogColumn
}

const getMinSize = (name?: string) => {
  if (name?.length) {
    return Math.max(name.length * 10, 100)
  }
  return 100
}

export const logColumnToTableColumn = (column: EventLogColumn): ColumnDef<any> => {
  // compatible with old schema
  let builtInCol: EventLogColumn | undefined = undefined
  if (column.id) {
    builtInCol = column.id ? DefaultEventLogColumns[column.id] : undefined
    if (!builtInCol && ObsoleteFacets[column.id]) {
      builtInCol = DefaultEventLogColumns[ObsoleteFacets[column.id]]
    }
  }
  if (builtInCol) {
    return {
      header: builtInCol.name,
      id: builtInCol.id,
      accessorKey: builtInCol.accessorKey || builtInCol.id || '',
      size: builtInCol.size || getMinSize(builtInCol.name),
      enableHiding: builtInCol.enableHiding,
      cell: builtInCol.id ? defaultShowColumnCells[builtInCol.id] || defaultColumnCell : defaultColumnCell,
    }
  }
  return {
    header: startCase(column.name),
    id: column.id,
    accessorKey: column.accessorKey || column.id || '',
    size: column.size || getMinSize(column.name),
    enableHiding: column.enableHiding,
    cell: defaultColumnCell,
  }
}

export type LogColumnStateAction =
  | {
      type: 'columnSizing'
      payload: Record<string, number>
    }
  | {
      type: 'columnVisibility'
      payload: Record<string, boolean>
    }
  | {
      type: 'columnVisibility.update'
      payload: {
        id: string
        visible: boolean
      }
    }
  | {
      type: 'columnOrder'
      payload: string[]
    }
  | {
      type: 'sorting'
      payload: ColumnStateSort[]
    }
  | {
      type: 'reset'
      payload: ColumnState
    }
export const logsColumnStates = atomWithReducer<ColumnState, LogColumnStateAction>({}, (prev, action) => {
  if (!action) {
    return prev
  }
  if (action.type === 'columnSizing') {
    return {
      ...prev,
      columnSizing: action.payload,
    }
  } else if (action.type === 'columnVisibility') {
    return {
      ...prev,
      columnVisibility: action.payload,
    }
  } else if (action.type === 'columnVisibility.update') {
    return {
      ...prev,
      columnVisibility: {
        ...prev.columnVisibility,
        [action.payload.id]: action.payload.visible,
      },
    }
  } else if (action.type === 'columnOrder') {
    return {
      ...prev,
      columnOrder: action.payload,
    }
  } else if (action.type === 'sorting') {
    return {
      ...prev,
      sorting: action.payload,
    }
  } else if (action.type === 'reset' && !isEqual(prev, action.payload)) {
    if (!action.payload) {
      return action.payload
    }

    const newState = parseObsoleteState(action.payload)
    if (!isEqual(prev, newState)) {
      return newState
    }

    return prev
  }
  return prev
})

export function parseObsoleteState(data: ColumnState) {
  const newState: ColumnState = {}
  const { columnSizing, columnVisibility, columnOrder, sorting } = data

  if (columnSizing) {
    const res = {}
    Object.keys(columnSizing).forEach((key) => {
      if (ObsoleteFacets[key]) {
        res[ObsoleteFacets[key]] = columnSizing[key]
      } else {
        res[key] = columnSizing[key]
      }
    })
    newState.columnSizing = res
  }
  if (columnVisibility) {
    const res = {}
    Object.keys(columnVisibility).forEach((key) => {
      if (ObsoleteFacets[key]) {
        res[ObsoleteFacets[key]] = columnVisibility[key]
      } else {
        res[key] = columnVisibility[key]
      }
    })
    newState.columnVisibility = res
  }
  if (columnOrder) {
    newState.columnOrder = columnOrder.map((item) => ObsoleteFacets[item] || item)
  }
  if (sorting) {
    newState.sorting = sorting.map((item) => {
      if (!item.id) {
        return item
      }
      if (ObsoleteFacets[item.id]) {
        return {
          ...item,
          id: ObsoleteFacets[item.id],
        }
      }
      return item
    })
  }
  return newState
}
