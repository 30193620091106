import { MetricsQueryResponse } from '../gen/service/observability'
import dayjs from 'dayjs'

import domtoimage from 'dom-to-image'
import { aliasTemplate } from './metrics/template'
import { Chart, ChartChartType } from '../gen/service/web'
import { getTableData } from './metrics/table'
import { computeSeries } from './metrics/series'
import { valueFormatter } from './metrics/formatter'
import { sum } from 'lodash'
import { defaultConfig as DefaultBarGaugeConfig } from '../components/charts/options/BarGaugeControls'
import { defaultConfig as DefaultQueryValueConfig } from '../components/charts/options/QueryValueControls'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { SyncExecuteSQLResponse } from '../gen/service/analytic'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.guess()

function csvLine(value: any[]) {
  return value.map((v) => `"${String(v).replace(/"/g, '""')}"`).join(',') + '\n'
}

export function exportCSVTable(name: string, columns: any[], rows: any[]) {
  let csv = csvLine(columns)
  rows.forEach((r) => {
    csv += csvLine(columns.map((c) => r[c]))
  })
  const anchor = document.createElement('a')
  anchor.href = URL.createObjectURL(new Blob([csv], { type: 'text/csv' }))
  anchor.download = `${name}.csv`
  anchor.style.display = 'none'
  anchor.click()
}

export function exportSQLCSV(name: string, data: SyncExecuteSQLResponse) {
  const columns = data.result?.columns || []
  const rows = data.result?.rows || []
  let csv = ''
  csv = csvLine(columns)
  rows.forEach((r) => {
    csv += csvLine(
      columns.map((c) => {
        if (c == 'time') {
          return dayjs.unix(parseInt(r[c])).format()
        }
        return r[c]
      })
    )
  })
  const anchor = document.createElement('a')
  anchor.href = URL.createObjectURL(new Blob([csv], { type: 'text/csv' }))
  anchor.download = `${name}.csv`
  anchor.style.display = 'none'
  anchor.click()
}

export function exportCSV(name: string, data: MetricsQueryResponse | SyncExecuteSQLResponse, chart: Chart) {
  if (data['result']) {
    return exportSQLCSV(name, data as SyncExecuteSQLResponse)
  }
  let csv = ''
  data = data as MetricsQueryResponse

  switch (chart?.type) {
    case ChartChartType.TABLE:
      {
        const d = getTableData(data as MetricsQueryResponse, chart.config)
        const rows = d.rows

        const showColumns = chart?.config?.tableConfig?.showColumns || {}
        const filteredCols = d.columns.filter((c) => showColumns[c.id] !== false)
        csv = csvLine(filteredCols.map((c) => c.header))
        rows.forEach((r, idx) => {
          csv += csvLine(
            filteredCols.map((c) => {
              if (c.id == 'time') {
                return dayjs.unix(parseInt(r[c.id])).format()
              }
              if (c.id == '__index') {
                return idx + 1
              }
              return r[c.id]
            })
          )
        })
      }
      break
    case ChartChartType.PIE:
      {
        const { series: seriesData } = computeSeries(
          data?.results || [],
          chart.type,
          chart.config?.pieConfig?.calculation
        )
        const d = seriesData.reduce((p, s) => {
          if (s.data.length > 0) {
            p[s.name] = s.data[0][1]
          }
          return p
        }, {})
        csv = csvLine(Object.keys(d))
        const config = chart?.config
        if (config?.pieConfig?.showValue) {
          const vf = valueFormatter(config?.valueConfig)
          csv += csvLine(Object.values(d).map((n) => vf(n as number)))
        } else if (config?.pieConfig?.showPercent) {
          const total = sum(Object.values(d))
          csv += csvLine(Object.values(d).map((v) => (((v as number) / total) * 100).toFixed(2) + '%'))
        } else {
          csv += csvLine(Object.values(d))
        }
      }
      break
    case ChartChartType.LINE:
    case ChartChartType.AREA:
    case ChartChartType.BAR:
      {
        const { series } = computeSeries(data?.results || [], chart.type)
        csv = 'name,timestamp,value\n'
        const vf = valueFormatter(chart.config?.valueConfig)
        series.forEach((s) => {
          s.data.forEach((value) => {
            const t = dayjs(value[0]).format()
            csv += csvLine([s.name, t, vf(value[1])])
          })
        })
      }
      break
    case ChartChartType.BAR_GAUGE:
      {
        const calculation = chart?.config?.barGauge?.calculation || DefaultBarGaugeConfig.calculation
        const { series } = computeSeries(data?.results || [], chart.type, calculation)
        const d = series.reduce((p, s) => {
          if (s.data.length > 0) {
            p[s.name] = s.data[0][1]
          }
          return p
        }, {})
        csv = csvLine(Object.keys(d))
        const vf = valueFormatter(chart?.config?.valueConfig)
        csv += csvLine(Object.values(d).map((v) => vf(v as number)))
      }
      break
    case ChartChartType.QUERY_VALUE:
      {
        const c = chart?.config?.queryValueConfig?.calculation || DefaultQueryValueConfig.calculation
        const { series } = computeSeries(data?.results || [], chart.type, c)
        csv = 'name,timestamp,value\n'
        const vf = valueFormatter(chart.config?.valueConfig)
        series.forEach((s) => {
          s.data.forEach((value) => {
            const t = dayjs(value[0]).format()
            csv += csvLine([s.name, t, vf(value[1])])
          })
        })
      }
      break
    default: // raw data
      csv = 'name,timestamp,value\n'
      ;(data.results || []).forEach((result) => {
        ;(result?.matrix?.samples || []).forEach((sample) => {
          const labels = sample.metric?.labels || {}
          const alias = result.alias || sample?.metric?.displayName || sample?.metric?.name
          const name = aliasTemplate(alias, labels)
          ;(sample.values || []).forEach((value) => {
            const t = dayjs.unix(parseInt(value.timestamp!)).format()
            csv += csvLine([name, t, value[1]])
          })
        })
      })
  }

  const anchor = document.createElement('a')
  anchor.href = URL.createObjectURL(new Blob([csv], { type: 'text/csv' }))
  anchor.download = `${name}.csv`
  anchor.style.display = 'none'
  anchor.click()
}

export function exportPNG(name: string, element?: HTMLDivElement | null) {
  if (element) {
    domtoimage.toPng(element, { bgcolor: 'white' }).then(function (dataUrl) {
      const link = document.createElement('a')
      link.download = `${name}.png`
      link.href = dataUrl
      link.click()
    })
  }
}

// todo: find a way to export a real svg render by echarts
export function exportSVG(name: string, element?: HTMLDivElement | null) {
  if (element) {
    domtoimage.toSvg(element, { bgcolor: 'white' }).then(function (dataUrl) {
      const link = document.createElement('a')
      link.download = `${name}.svg`
      link.href = dataUrl
      link.click()
    })
  }
}
