import { useCallback, useContext } from 'react'
import { Panel } from 'gen/service/web'
import { MemoNoteMarkdown } from './NoteMarkdown'
import { DashboardButtonsMemo } from '../dashboard/DashboardButtons'
import copy from 'copy-to-clipboard'
import { NotificationContext } from 'lib/data/use-notification'
import classNames from 'lib/classnames'

interface Props {
  panel: Panel
  allowEdit?: boolean
  onEditPanel?: (panel: Panel) => void
  onRemovePanel?: (id: string) => void
  onClonePanel?: (id: string) => void
}

export const NotePanel = ({ panel, allowEdit, onEditPanel, onRemovePanel, onClonePanel }: Props) => {
  const notification = useContext(NotificationContext)
  const onMenuSelect = useCallback(
    (selectedKey: string) => {
      switch (selectedKey) {
        case 'delete':
          panel.id && onRemovePanel?.(panel.id)
          break
        case 'edit':
          panel.id && onEditPanel?.(panel)
          break
        case 'clone':
          panel.id && onClonePanel?.(panel.id)
          break
        case 'copy':
          if (panel.id) {
            copy(
              JSON.stringify({
                name: panel.name,
                chart: panel.chart
              })
            )
            notification.showNotification(
              {
                title: 'Copy configuration success',
                message: 'Panel copied to clipboard',
                type: 'success'
              },
              3
            )
          }
          break
        default:
          break
      }
    },
    [onClonePanel, onEditPanel, onRemovePanel, panel, notification]
  )

  return (
    <div
      className={classNames(
        'border-border-color border',
        'dark:bg-sentio-gray-100 flex h-full w-full flex-col rounded-lg bg-white p-px',
        'group'
      )}
      style={{
        backgroundColor: panel?.chart?.note?.backgroundColor
      }}
    >
      <div className="scrollbar-inner grid h-full w-full overflow-hidden hover:overflow-auto">
        <MemoNoteMarkdown
          content={panel?.chart?.note?.content}
          fontSize={panel?.chart?.note?.fontSize}
          align={panel?.chart?.note?.textAlign}
          verticalAlign={panel?.chart?.note?.verticalAlign}
          backgroundColor={panel?.chart?.note?.backgroundColor}
          textColor={panel?.chart?.note?.textColor}
          inPanel={true}
        />
      </div>
      <div className="draggableHandle absolute left-0 right-0 flex w-full cursor-move justify-end px-2 pt-2">
        <div className="nonDraggable invisible flex min-w-fit group-hover:visible">
          <div
            className={classNames(
              'rounded-md px-1 py-0.5',
              panel?.chart?.note?.backgroundColor ? 'border border-gray-300  bg-gray-50' : ''
            )}
          >
            <DashboardButtonsMemo
              allowEdit={allowEdit}
              onMenuSelect={onMenuSelect}
              allowFullScreen={false}
              allowExport={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
