import { ChartDataSourceType, Dashboard, Panel } from 'gen/service/web'
import { Panel as PanelComponent } from 'components/common/panel/Panel'
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTimeRangeWithOverrideConfig } from 'lib/data/use-timerange'
import ChartComponent from '../charts/Chart'
import { CurlDialog } from './CurlDialog'
import { SnapshotDialog } from './SnapshotDialog'
import { DashboardButtonsMemo } from './DashboardButtons'
import { exportCSV, exportPNG, exportSVG } from 'lib/exportchart'
import { TimeRangeLabel } from '../timerange/TimeRangeLabel'
import { useTemplateValues } from 'lib/data/use-template-values'
import { mapValues, template } from 'lodash'
import { usePanelQueryData } from 'lib/data/use-panel-query-data'
import { ErrorChart } from './ErrorChart'
import { QueryRangeContext } from 'lib/context/query-range-context'
import { LogChart } from 'components/logs/LogChart'
import { useRouter } from 'next/router'
import { EChartsHandle } from '../charts/EchartsBase'
import { SyncExecuteSQLResponse } from '@sentio/service/analytic'
import { RetentionChart } from '../retention/RetentionChart'
import { RetentionSeries } from '../../lib/data/use-retention'
import { RetentionRequest } from '../../gen/service/insights'
import SqlChart from '../charts/SqlChart'
import { MetricsQueryResponse } from '../../gen/service/observability'
import { chartConfigToMarkLines } from 'components/charts/options/MarkerControls'
import copy from 'copy-to-clipboard'
import { ProcessorStatusIcon } from '../processor/ProcessorStatusIcon'
import { DashboardRefresh } from './DashboardRefresh'
import { ComputeStats } from 'gen/service/common/protos/common.pb'
import { NotificationContext } from 'lib/data/use-notification'
import { InView } from 'react-intersection-observer'

interface Props {
  allowEdit?: boolean
  dashboard: Dashboard
  panel: Panel
  onEditPanel?: (panel: Panel) => void
  onRemovePanel?: (id: string) => void
  onFullScreen?: (panel: Panel) => void
  onClonePanel?: (id: string) => void
  disableRefresh?: boolean
  noHide?: boolean
  allowClick?: boolean
}

const HIDE_TIMEOUT = 10000
const CHART_STYLE = { height: undefined }
const VISIBILITY_SENSOR_OFFSET = '10px 0px 10px 0px'

export function DashboardPanel({
  allowEdit,
  panel,
  onEditPanel,
  onRemovePanel,
  dashboard,
  onFullScreen,
  onClonePanel,
  disableRefresh,
  noHide,
  allowClick = true
}: Props) {
  const router = useRouter()
  const [showSnapshot, setShowSnapshot] = useState(false)
  const [showCurlDialog, setShowCurlDialog] = useState(false)
  const chartRef = useRef<EChartsHandle>(null)
  const chart = panel.chart
  const timeRangeOverride = chart?.config?.timeRangeOverride
  const hideTimeoutRef = useRef<any>(null)
  const [visible, setVisible] = useState(Boolean(noHide))
  const { isShare } = useContext(QueryRangeContext) || {}
  // add refs to optimize performance
  const panelRef = useRef<Panel | null>(null)
  const dataRef = useRef<any>(null)
  const isMutatingRef = useRef<boolean>(false)
  const [dataComputeState, setDataComputeState] = useState<ComputeStats | undefined>()
  const notification = useContext(NotificationContext)

  const { startTime, endTime, setTimeRange, tz } = useTimeRangeWithOverrideConfig(
    timeRangeOverride,
    undefined,
    `dashboard.${dashboard.id}`
  )
  const { templateValues } = useTemplateValues(dashboard?.extra?.templateVariables)
  const isEvents = chart?.datasourceType === ChartDataSourceType.EVENTS
  const { data, compareData, loading, ready, payload, apiUrl, error, bypassCacheMutate } = usePanelQueryData({
    projectId: dashboard?.projectId,
    panel,
    startTime,
    endTime,
    tz,
    templateValues,
    refreshInterval: disableRefresh ? 0 : 30000,
    enabled: visible && !isEvents
  })

  useEffect(() => {
    panelRef.current = panel
  }, [panel])
  useEffect(() => {
    dataRef.current = data
    if ((data as any)?.results?.[0]?.computeStats) {
      setDataComputeState((data as any).results[0].computeStats)
    } else if ((data as any)?.computeStats?.computedAt) {
      setDataComputeState((data as any).computeStats)
    }
  }, [data])

  const onMenuSelect = useCallback(
    (selectKey: string) => {
      const { name = '', id = '', chart } = panelRef.current || {}
      switch (selectKey) {
        case 'snapshot':
          setShowSnapshot(true)
          break
        case 'png':
          exportPNG(name, chartRef?.current?.getFrame())
          break
        case 'svg':
          exportSVG(name, chartRef?.current?.getFrame())
          break
        case 'csv':
          if (chart && dataRef.current) {
            exportCSV(name, dataRef.current, chart)
          }
          break
        case 'curl':
          setShowCurlDialog(true)
          break
        case 'clone':
          onClonePanel?.(id)
          break
        case 'copy':
          copy(
            JSON.stringify({
              name: `${panel.name} (copy)`,
              chart
            })
          )
          notification.showNotification(
            {
              title: 'Copy configuration success',
              message: 'Panel copied to clipboard',
              type: 'success'
            },
            3
          )
          break
        case 'delete':
          onRemovePanel?.(id)
          break
        case 'fullscreen':
          panelRef.current && onFullScreen?.(panelRef.current)
          break
        case 'edit': {
          if (panelRef.current) {
            onEditPanel?.(panelRef.current)
          }
          break
        }
        case 'refresh': {
          if (bypassCacheMutate && !isMutatingRef.current) {
            isMutatingRef.current = true
            bypassCacheMutate?.().finally(() => {
              isMutatingRef.current = false
            })
          }
          break
        }
      }
    },

    [onClonePanel, onRemovePanel, onFullScreen, onEditPanel, router, bypassCacheMutate, notification]
  )

  const title = useMemo(() => {
    try {
      const compiled = template(panel.name, { interpolate: /\$(\S+)/g })
      return compiled(
        mapValues(templateValues, (v, k) => {
          return `${v || '*'}`
        })
      )
    } catch (e) {
      return panel.name
    }
  }, [templateValues, panel.name])

  const onVisibleChange = useCallback(
    (v: boolean) => {
      setVisible((preVisible) => {
        if (v === true) {
          if (hideTimeoutRef.current) {
            clearTimeout(hideTimeoutRef.current)
          }
        } else if (preVisible !== v) {
          if (hideTimeoutRef.current) {
            clearTimeout(hideTimeoutRef.current)
          }
          // hide after out of page view for 10s
          hideTimeoutRef.current = setTimeout(() => {
            setVisible(false)
            hideTimeoutRef.current = null
          }, HIDE_TIMEOUT)
          return true
        }
        return v
      })
      return () => {
        if (hideTimeoutRef.current) {
          clearTimeout(hideTimeoutRef.current)
        }
      }
    },
    [setVisible]
  )

  const onSnapshotClose = useCallback(() => {
    setShowSnapshot(false)
  }, [])

  const onCurlDialogClose = useCallback(() => {
    setShowCurlDialog(false)
  }, [])

  const segmentationQueriesRef = useRef<any>(null)
  useEffect(() => {
    segmentationQueriesRef.current = chart?.segmentationQueries
  }, [chart])
  const getEventNameById = useCallback((id?: string) => {
    const queries = segmentationQueriesRef.current
    if (queries && id) {
      const query = queries.find((q: any) => q.id === id)
      return query?.resource.name
    }
    return ''
  }, [])

  let contentNode: React.ReactNode
  let addons: React.ReactNode = null
  let allowExport = true
  const allowRefresh = !!bypassCacheMutate
  const addonRef = useRef<HTMLDivElement>(null)
  const onLogCountChange = useCallback((count: string) => {
    if (addonRef.current) {
      addonRef.current.innerText = `(${count} logs found)`
    }
  }, [])
  const chartDataRef = useRef<any>(null)
  useEffect(() => {
    chartDataRef.current = chart
  }, [chart])

  if (error && !data) {
    contentNode = <ErrorChart data={error} />
  } else if (visible && chart) {
    switch (chart.datasourceType) {
      case ChartDataSourceType.EVENTS:
        contentNode = (
          <LogChart
            projectId={dashboard.projectId}
            config={chart.eventLogsConfig}
            updateRowCount={onLogCountChange}
            variables={dashboard?.extra?.templateVariables}
            panelId={panel.id}
          />
        )
        allowExport = false
        addons = <div className="text-gray ml-2 truncate text-xs" ref={addonRef}></div>
        break
      case ChartDataSourceType.RETENTION:
        contentNode = (
          <RetentionChart
            data={data as Record<string, RetentionSeries>}
            tz={tz}
            loading={visible && ready && loading}
            interval={(payload as RetentionRequest)?.query?.interval}
            windowSize={(payload as RetentionRequest)?.query?.windowSize}
            chartDataRef={chartDataRef}
            style={CHART_STYLE}
          />
        )
        break
      case ChartDataSourceType.SQL:
        contentNode = (
          <SqlChart
            style={CHART_STYLE}
            data={data as SyncExecuteSQLResponse}
            loading={loading}
            group="panel"
            config={chart.config}
            chartType={chart.type}
          />
        )
        addons = (
          <div className="flex h-5 w-full items-center gap-1 pl-1">
            <ProcessorStatusIcon projectId={dashboard.projectId} />
          </div>
        )
        break
      default:
        contentNode = (
          <ChartComponent
            style={CHART_STYLE}
            ref={chartRef}
            group="panel"
            data={data as MetricsQueryResponse}
            compareData={compareData as MetricsQueryResponse}
            loading={visible && ready && loading}
            startTime={startTime}
            endTime={endTime}
            tz={tz}
            onSelectTimeRange={setTimeRange}
            config={chart.config}
            chartType={chart.type}
            panelId={panel.id}
            allowClick={allowClick}
            sourceType={chart.datasourceType}
            getEventNameById={getEventNameById}
            chartDataRef={chartDataRef}
            markLines={chartConfigToMarkLines(chart.config?.markers)}
            markAreas={[{ from: new Date(2023, 10, 1, 0, 0, 0), to: new Date(2023, 10, 1, 0, 0, 1) }]}
          />
        )
        addons = (
          <div className="flex h-5 w-full items-center gap-1 pl-1">
            {timeRangeOverride?.enabled && <TimeRangeLabel startTime={startTime} endTime={endTime} />}
            <ProcessorStatusIcon projectId={dashboard.projectId} />
          </div>
        )
    }
  }

  const panelEl = (
    <PanelComponent
      id={panel.id}
      title={title}
      buttons={<DashboardButtonsMemo allowEdit={allowEdit} onMenuSelect={onMenuSelect} allowExport={allowExport} />}
      addons={addons}
      allowEdit={allowEdit}
      titleExtra={
        allowRefresh && dataComputeState ? (
          <DashboardRefresh
            stats={dataComputeState}
            onRefresh={() => {
              return new Promise((resolve) => {
                if (bypassCacheMutate && !isMutatingRef.current) {
                  isMutatingRef.current = true
                  bypassCacheMutate?.().finally(() => {
                    isMutatingRef.current = false
                    resolve()
                  })
                } else {
                  resolve()
                }
              })
            }}
          />
        ) : null
      }
    >
      {contentNode}
    </PanelComponent>
  )

  return (
    <>
      {' '}
      {noHide ? (
        panelEl
      ) : (
        <InView onChange={onVisibleChange} rootMargin={VISIBILITY_SENSOR_OFFSET} className="h-full w-full">
          {panelEl}
        </InView>
      )}
      {chart && dashboard.projectId && !isShare && (
        <SnapshotDialog
          defaultName={`Snapshot for ${panel.name}`}
          chart={chart}
          data={data as MetricsQueryResponse}
          open={showSnapshot}
          startTime={startTime}
          endTime={endTime}
          onClose={onSnapshotClose}
          projectId={dashboard.projectId}
        />
      )}
      {!isShare && payload && (
        <CurlDialog open={showCurlDialog} payload={payload} apiUrl={apiUrl} onClose={onCurlDialogClose} />
      )}
    </>
  )
}

export const DashboardPanelMemo = memo(DashboardPanel)
