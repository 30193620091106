import { CodeBlock as ReactCodeBlock, atomOneDark, atomOneLight } from 'react-code-blocks'
import React, { ReactNode } from 'react'
import { useDarkMode } from '../../lib/util/use-dark-mode'
import { Fira_Code } from 'next/font/google'
import classNames from '../../lib/classnames'
import { CopyButton } from '../common/buttons/CopyButton'

const firaCode = Fira_Code({
  subsets: ['latin']
})

export type SupportedLanguages =
  | 'bash'
  | 'c'
  | 'clojure'
  | 'cpp'
  | 'csharp'
  | 'dart'
  | 'elixir'
  | 'elm'
  | 'erlang'
  | 'fsharp'
  | 'graphql'
  | 'go'
  | 'groovy'
  | 'haskell'
  | 'html'
  | 'java'
  | 'javascript'
  | 'jsx'
  | 'julia'
  | 'kotlin'
  | 'lisp'
  | 'makefile'
  | 'matlab'
  | 'objectivec'
  | 'ocaml'
  | 'php'
  | 'python'
  | 'r'
  | 'ruby'
  | 'rust'
  | 'scala'
  | 'sql'
  | 'swift'
  | 'tsx'
  | 'typescript'

interface Props {
  value: string
  language: SupportedLanguages
  showLineNumbers?: boolean
  wrapLines?: boolean
}

export const CodeBlock = ({ value, language, showLineNumbers, wrapLines }: Props) => {
  const theme = useDarkMode() ? atomOneDark : atomOneLight
  return (
    <div className={firaCode.className}>
      <ReactCodeBlock
        text={value}
        showLineNumbers={showLineNumbers}
        theme={theme}
        language={language}
        wrapLongLines={wrapLines}
      />
    </div>
  )
}

export const CodeBlockWithTitle = ({
  title,
  icon,
  value,
  language,
  showLineNumbers,
  maxHeight = '300px'
}: Props & {
  title: string
  icon?: ReactNode
  maxHeight?: string
}) => {
  const theme = useDarkMode() ? atomOneDark : atomOneLight
  return (
    <div className={classNames('rounde-md text-icontent overflow-hidden rounded-lg border border-gray-300')}>
      <div className="flex w-full items-center justify-between border-b bg-gray-200 p-2">
        <span className="text-ilabel font-medium">
          {icon}
          {title}
        </span>
        <CopyButton text={value} iconClass="h-4 w-4 text-gray" />
      </div>
      <ReactCodeBlock
        text={value}
        showLineNumbers={showLineNumbers}
        theme={theme}
        language={language}
        codeContainerStyle={{
          ...firaCode.style,
          overflow: 'auto',
          maxHeight: maxHeight,
          minWidth: '100%'
        }}
      />
    </div>
  )
}
